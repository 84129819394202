@import '../../style/index.scss';

.home{
  min-height: 100vh;
  // background: url(../../assets/img/bg.png) no-repeat top center;
  background-color: #000;
  background-size: 100%;
  #main{
    max-width: 450px;
    width: 100%;
    min-height: 100vh;
    padding-bottom: 120px;
    margin: auto;
    position: relative;
    .header{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 22px;
      .logo{
        img{
          height: 24px;
        }
      }
      .lang{
        .lang_box{
          display: flex;
          border-radius: 7px;
          background: rgba(0, 0, 0, 0.2);
          box-sizing: border-box;
          border: 1px solid rgba(15, 243, 246, 0.41);
          backdrop-filter: blur(27.2px);
          box-shadow: inset 0px -10px 21px 0px rgba(17, 240, 244, 0.11);
          padding: 1px;
          .item{
            width: 35px;
            height: 22.29px;
            border-radius: 5px;
            opacity: 1;

            font-family: Avenir;
            font-size: 13px;
            font-weight: 800;
            line-height: 17px;
            text-transform: uppercase;
            letter-spacing: 0px;
            transition: all linear .3s;

            color: #4CF1FF;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            &.active{
              background: #4CF1FF;
              color: #000;
            }
          }
        }
      }
    }
    .top{
      width: 100%;
      height: 770px;
      position: relative;
      background: url(../../assets/img/bg.png) no-repeat top center;
      background-color: #000;
      background-size: 100%;
      background-position: 50% -116px;
      .top-model{
        width: 100%;
        height: 324px;
        opacity: 1;
        background: linear-gradient(180deg, #0E0C2F 27%, rgba(6,5,21,0.00) 98%);
        position: absolute;
        top: 0;
        left: 0;
      }
      .bottom-model{
        width: 100%;
        height: 406px;
        opacity: 1;
        position: absolute;
        bottom: 0;
        left: 0;

        background: linear-gradient(180deg, rgba(0,0,0,0.00) 2%, #000000 12%);
        z-index: 2;
      }
      .text-logo{
        padding-top: 127px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          height: 192px;
        }
      }
      .people{
        position: absolute;
        top: 72px;
        left: 50%;
        transform: translateX(-50%);
        img{
          width: 225px;
        }
      }
      .car{
        margin: 0 18px;
        display: flex;
          align-items: center;
          justify-content: center;
          padding: 18px 0;
          margin-bottom: 20px;
          border: none;
          border-radius: 13px;
          // background: rgba(76, 241, 255, 0.93);
          background-color: rgba(70, 225, 238, 0.46);
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid ;
          border-image: linear-gradient(166deg, rgba(2,241,244,0.00) 75%, rgba(2,241,245,0.60) 84%, #02F1F8 101%) ;
          position: relative;
          z-index: 99;
        .car_icon{
          height: 40px;
          margin-right: 24px;
        }
        .logo{
          height: 28px;
        }
      }
      .text{
        position: relative;
        font-size: 20px;
        font-weight: 800;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
        z-index: 3;
        margin-top: 20px;
      }
      .join{
        position: relative;
        z-index: 3;
        padding: 0 18px;
        margin-top: 42px;
        margin-top: 20px;
        .btn{
          background: url(../../assets/img/block_bg.png) no-repeat center;
          background-size: cover;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 24px 0;
          font-size: 24px;
          font-weight: 800;
          line-height: 29px;
          text-align: center;
          color: #4CF1FF;
          border-radius: 13px;
        }
      }
      .social{
        position: relative;
        // bottom: -31px;
        // left: 0;
        margin-top: 20px;
        width: 100%;
        padding: 0 18px;
        z-index: 3;
        display: flex;
        flex-direction: column;
        .item{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 18px 0;
          margin-bottom: 20px;
          border: none;
          border-radius: 13px;
          background: rgba(76, 241, 255, 0.93);
          cursor: pointer;
          box-sizing: border-box;
          border: 1px solid ;
          border-image: linear-gradient(166deg, rgba(2,241,244,0.00) 75%, rgba(2,241,245,0.60) 84%, #02F1F8 101%) ;
          font-size: 24px;
          font-weight: 800;
          line-height: 29px;
          text-align: center;
          color: #000000;
          img{
            width: 40px;
            margin-right: 24px;
          }
        }
      }
    }
    .footer{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 6;
      width: 100%;
      height: 78px;
      opacity: 1;
      background: rgba(70, 225, 238, 0.16);
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        height: 24px;
      }
    }
  }
}


@media (max-width: 640px) {
  .home{
    // background: url(../../assets/img/bg.png) no-repeat top center;
    background-color: #000;
    min-height: auto;
    #main{
      max-width: 100%;
      padding-bottom: 160px;
      .top{
        background-position: 50% -62px;
      }
    }
  }
 }