iframe {
  display: none;
}


/*  GLOBAL */

/* TEXT */


html {
margin:0px;
padding:0px;
// height: 100%;
}

*{
  font-family: 'Oblique';
}


body {
margin:0px;
padding:0px;
// height: 100%;
}


a {
text-decoration: none;
}


